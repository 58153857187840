//
export const ENVIRONMENT_GLOBAL = {
  apiServer: null,
  appStoreLink: 'https://itunes.apple.com/us/app/one-medical-mobile/id393507802?mt=8',
  auth0: null,
  contactSupportLink: 'https://www.onemedical.com/contact-us',
  designSystemUrl: null,
  devRoutes: false,
  enableProdMode: false,
  googlePlacesKey: 'AIzaSyB4sIj1mQkgx9bGRP614h-ub3DJMEj-WMQ',
  googlePlayLink: 'https://play.google.com/store/apps/details?id=com.onemedical.android&hl=en',
  googleTagManagerId: 'GTM-5PQD',
  googleConversionId: 1039580055,
  marketingSite: 'https://mktg-stage.onemedical.com',
  mixpanelToken: '44008e1ef10bb5ecc7b4f967257c824d',
  mixpanelDebug: false,
  myoneServer: null,
  newRelic: null,
  oneMedicalPhone: '415-291-0480',
  opsUrl: 'https://staging.patientops.com',
  pusherKey: '5422148b626236f6bff3',
  recaptchaSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  rollbarAccessToken: '',
  rollbarEndpoint: '',
  rollbarEnvironment: '',
  stripePublishableKey: 'pk_test_n8l9UNf6ai3uDaNVAiuWp8Tu',
  topLevelDomain: '',
  typeaheadDataLoggingUrl: 'https://any-ac-ml-qualification.app.1life.com/data-logging/member',
  typeaheadSearchUrl: 'https://any-ac-ml-qualification.app.1life.com/autocomplete/member',
  typeaheadTestingMode: true,
};
