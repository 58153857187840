import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { AnalyticsService } from '@app/core/analytics.service';
import { AnonymousFlagDataService, AnonymousFlagName } from '@app/shared';

@Component({
  selector: 'om-login',
  providers: [],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
// Component controller
export class Login implements OnInit {
  signInText = 'Please log in to your One Medical account';

  constructor(
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private anonymousFlagDataService: AnonymousFlagDataService,
    private analyticsService: AnalyticsService,
  ) {}

  ngOnInit() {
    this.title.setTitle('Login');
    this.initializeCustomSignInText();
    this.activatedRoute.queryParams.subscribe(params => {
      this.analyticsService.trackLoginPageView(params);
    });
  }

  initializeCustomSignInText() {
    // Custom Login Text for the Pediatric Family Promotion
    this.anonymousFlagDataService
      .enabledByName$(AnonymousFlagName.FAMILY_PROMOTIONAL_PRICING)
      .subscribe(familyPromotionalPricingEnabled => {
        if (familyPromotionalPricingEnabled) {
          const isFamilyPromo = this.activatedRoute.snapshot.queryParams.familyPromo;
          if (isFamilyPromo === 'true') {
            this.signInText =
              'Sign in to create an account for your kid at a special price of $75 for their first year (normally $199).';
          }
        }
      });
  }
}
