import { Params } from '@angular/router';
import * as fromRouter from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import * as fromAnalytics from '@app/core/analytics.reducer';

import * as fromAuth from './auth.reducer';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export interface State {
  analytics: fromAnalytics.AnalyticsState;
  auth: fromAuth.IAuthState;
  router: fromRouter.RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<State> = {
  analytics: fromAnalytics.reducer,
  auth: fromAuth.reducer,
  router: fromRouter.routerReducer,
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys: ['auth', 'register'], rehydrate: true })(reducer);
}

export const metaReducers: MetaReducer<State>[] = [];

export const selectRouter = createFeatureSelector<State, fromRouter.RouterReducerState<any>>('router');
