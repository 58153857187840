import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { tap, withLatestFrom } from 'rxjs/operators';

import { getAccessToken } from '@app/auth.reducer';
import { AuthService } from '@app/core/auth.service';
import {
  registerInit,
  registerBasicInfoSuccess,
  registerReset,
} from '@app/registration/consumer/consumer-registration.actions';

import { State } from './app.reducer';

@Injectable()
export class AuthEffects {
  register$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(registerBasicInfoSuccess, registerInit),
        withLatestFrom(this.store.pipe(select(getAccessToken))),
        tap(([action, token]) => {
          if (token) {
            this.authService.setToken(token);
          }
        }),
      ),
    { dispatch: false },
  );

  registerReset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(registerReset),
        tap(() => {
          this.authService.logout('/register');
        }),
      ),
    { dispatch: false },
  );

  constructor(private actions$: Actions, private store: Store<State>, private authService: AuthService) {}
}
