import { Injectable } from '@angular/core';
import debounce from 'lodash-es/debounce';
import { BehaviorSubject, Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics.service';
import { ApiService } from '@app/core/api.service';
import { Membership } from '@app/core/membership';
import { PusherService } from '@app/shared/pusher.service';
import { User } from '@app/shared/user';

export interface ApiV2Response {
  b2b_company?: {
    display_name: string;
    return_to_work: boolean;
    return_to_work_client?: {
      show_homepage_survey_card: boolean;
    };
  };
  can_book_visit: boolean;
  can_cancel: boolean;
  can_convert_to_direct: boolean;
  can_reactivate: boolean;
  can_update_b2b_code: boolean;
  can_update_billing: boolean;
  is_active: boolean;
  credit_card?: {
    brand: string;
    last4: string;
    exp_month: string;
    exp_year: string;
  };
  expiration_date: {
    date: string;
    action: string;
  };
  patient_status: string;
  plan_id: number;
  plan_type: string;
  release_required_for_rtw_screener?: boolean;
  renewal_plan?: {
    amount: number;
  };
  previous_enterprise_membership?: {
    b2b_company: {
      id: number;
      name?: string;
      display_name?: string;
    };
  };
  status: string;
  valid_until: string;
  om_membership_type: string;
  is_enterprise_transitional?: boolean;
  trial_until: string;
  deactivate_at_trial_end: boolean;
  family_promotion_discount_type?: string;
}

interface CreateOrRenewConsumerMembershipResponse {
  error: string;
  membership_id: number;
  success: boolean;
}

interface ChargeMembershipResponse {
  error: string;
  success: any;
  message: string;
}

interface UpdateCreditCardResponse {
  success: any;
  message: string;
}

interface AsyncChannelResponse {
  channel_name: string;
  event_name: string;
}

@Injectable()
export class MembershipService {
  private _membership$ = new BehaviorSubject<Membership>(null);
  private _debouncedGetMembership;

  readonly membership$: Observable<Membership>;

  constructor(
    private apiService: ApiService,
    private pusherService: PusherService,
    private analytics: AnalyticsService,
  ) {
    this.membership$ = this._membership$.asObservable().pipe(
      filter(membership => membership != null),
      tap((membership: Membership) => this.analytics.updateMembershipProperties(membership)),
    );

    this._debouncedGetMembership = debounce(this._getMembership.bind(this), 1000, {
      leading: true,
    });
  }

  getMembership(force = false) {
    if (force) {
      this._getMembership();
    } else if (this._membership$.getValue() == null) {
      this._debouncedGetMembership();
    }

    return this.membership$;
  }

  // this renews consumer membership or creates a new consumer membership if member was b2b
  createOrRenewConsumerMembership(
    stripeTokenId: string,
    coupon?: string,
    giftCode?: string,
  ): Subject<CreateOrRenewConsumerMembershipResponse> {
    const stripeResponse = new Subject<CreateOrRenewConsumerMembershipResponse>();

    const params = {
      stripe_token: stripeTokenId,
      callback_type: 'pusher',
    };

    if (coupon) {
      params['stripe_coupon_id'] = coupon;
    }

    if (giftCode) {
      params['gift_code'] = giftCode;
    }

    this.apiService.patch('/api/v2/patient/membership', params).subscribe({
      next: ({ channel_name, event_name }: AsyncChannelResponse) => {
        const channel = this.pusherService.subscribeTo(channel_name);
        channel.bind(event_name, (response: CreateOrRenewConsumerMembershipResponse) => {
          if (!response.error) {
            stripeResponse.next(response);
          } else {
            stripeResponse.error(response.error);
          }
          channel.unbind(event_name);
          stripeResponse.complete();
        });
      },
      error: error => stripeResponse.error(error),
    });

    return stripeResponse;
  }

  chargeMembership(
    stripeTokenId: string,
    membershipId: number,
    { coupon, code, giftCode }: { coupon?: string; code?: string; giftCode?: string } = {},
  ): Subject<ChargeMembershipResponse> {
    const stripeResponse = new Subject<ChargeMembershipResponse>();
    const params = {
      stripe_token: stripeTokenId,
      membership_id: membershipId,
      coupon,
      code,
      gift_code: giftCode,
    };

    this.apiService.post('/api/v2/patient/pediatric/membership/charge', params).subscribe({
      next: ({ channel_name, event_name }: AsyncChannelResponse) => {
        const channel = this.pusherService.subscribeTo(channel_name);
        channel.bind(event_name, (response: ChargeMembershipResponse) => {
          if (!response.error) {
            stripeResponse.next(response);
          } else {
            stripeResponse.error(response.error);
          }
          channel.unbind(event_name);
          stripeResponse.complete();
        });
      },
      error: error => stripeResponse.error(error),
    });

    return stripeResponse;
  }

  addSubscription(
    beneficiary: User,
    params: { stripeToken?: string; coupon?: string } = {},
  ): Subject<ChargeMembershipResponse> {
    const stripeResponse = new Subject<ChargeMembershipResponse>();

    this.apiService.post(`/api/v2/patient/beneficiaries/${beneficiary.id}/subscription`, params).subscribe({
      next: ({ channel_name, event_name }: AsyncChannelResponse) => {
        const channel = this.pusherService.subscribeTo(channel_name);
        channel.bind(event_name, (response: ChargeMembershipResponse) => {
          if (!response.error) {
            stripeResponse.next(response);
          } else {
            stripeResponse.error(response);
          }
          channel.unbind(event_name);
          stripeResponse.complete();
        });
      },
      error: error => stripeResponse.error(error),
    });

    return stripeResponse;
  }

  deactivateMembership(deactivateReasonId: number, deactivateReasonNotes?: string) {
    return this.apiService
      .post('/api/v2/patient/membership/deactivate_recurring_billing', {
        deactivate_recurring_billing_reason: deactivateReasonId,
        deactivate_recurring_billing_notes: deactivateReasonNotes,
      })
      .pipe(catchError(error => observableThrowError(error)));
  }

  reactivateMembership() {
    return this.apiService.post('/api/v2/patient/membership/reactivate_recurring_billing', {});
  }

  convertTrialMembership() {
    return this.apiService.post('/api/v2/patient/membership/convert_trial_membership', {});
  }

  getNewMembershipPrice(serviceAreaCode: string) {
    return this.apiService.get(
      `/pt/registration/membership_price?new_reg_plan=true&service_area_code=${serviceAreaCode}`,
    );
  }

  updateCreditCard(stripeTokenId: string, coupon?: string) {
    const params = {
      stripe_token: stripeTokenId,
    };
    if (coupon) {
      params['coupon'] = coupon;
    }

    return this._callUpdateCcEndpoint(params);
  }

  private _callUpdateCcEndpoint(params) {
    const stripeResponse = new Subject<UpdateCreditCardResponse>();

    this.apiService.patch('/api/v2/patient/memberships/credit_card', params).subscribe({
      next: ({ channel_name, event_name }: AsyncChannelResponse) => {
        const channel = this.pusherService.subscribeTo(channel_name);
        channel.bind(event_name, (response: UpdateCreditCardResponse) => {
          if (response.success) {
            this.getMembership(true);
            stripeResponse.next(response);
          } else {
            stripeResponse.error(response.message);
          }
          channel.unbind(event_name);
          stripeResponse.complete();
        });
      },
      error: error => stripeResponse.error(error),
    });

    return stripeResponse;
  }

  private _getMembership(ignoreUnauthorized = false) {
    const request = this.apiService
      .get('/api/v2/patient/membership', ignoreUnauthorized)
      .pipe(map((membership: ApiV2Response) => Membership.fromApiV2(membership)));
    request.subscribe(membership => {
      this._membership$.next(membership);
    });
    return request;
  }

  getMembershipWithRequest() {
    return this._getMembership(true);
  }
}
